import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Card } from '@material-ui/core';
import { BoxCenterContent } from '../ui-components/BoxCenterContent/BoxCenterContent';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    paddingTop: theme.remSpacing(20)
  },
  card: {
    width: 600,
    height: 300,
    margin: theme.remSpacing(0, 6, 6, 6)
  },
  title: {
    color: theme.colors.oxfordBlue06
  },
  textContainer: {
    width: 440,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    padding: theme.remSpacing(6, 6, 6, 6)
  }
}));

const Page404 = () => {
  const styles = useStyles();

  return (
    <BoxCenterContent className={styles.container}>
      <Card className={styles.card}>
        <BoxCenterContent>
          <div className={styles.textContainer}>
            <Typography className={styles.title} variant="h1" component="div">
              {Localization.getInst().localizedString(LocalizationKey.pageNotFoundTitle)}
            </Typography>
            <Typography variant="h3" component="div">
              {Localization.getInst().localizedString(LocalizationKey.pageDoesNotExist)}
            </Typography>
            <Typography variant="h4" component="div">
              {Localization.getInst().localizedString(
                LocalizationKey.youMayHaveMistypedTheAddressOrThePageMayhaveMoved
              )}
            </Typography>
          </div>
        </BoxCenterContent>
      </Card>
    </BoxCenterContent>
  );
};

export default () => <Page404 />;
